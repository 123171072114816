<template>
    <div class="authwrapper">
        <b-overlay :show="show_overlay" rounded="sm">
            <b-card header="LOGIN" class="lghead">
                <div class="pb-4 pl-3 pr-3">
                    <p>Welcome to MESON</p>

                    <!-- email -->
                    <b-form-group class="mt-4" label="Email">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <feather-icon icon="MailIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-model="email" type="email" placeholder="Email" v-bind:[emailState]="emailValidation" />
                        </b-input-group>
                        <b-form-invalid-feedback v-bind:[emailState]="emailValidation"> email format error </b-form-invalid-feedback>
                    </b-form-group>

                    <!-- password -->
                    <b-form-group label="Password">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <feather-icon icon="LockIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-model="password" type="password" v-bind:[passWordState]="passWordValidation" placeholder="Password" />
                        </b-input-group>
                        <b-form-invalid-feedback v-bind:[passWordState]="passWordValidation">
                            must contain number and letter,length 6-20.
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <!-- captcha   -->
                    <b-form-group label="Captcha">
                        <b-input-group>
                            <b-input-group-prepend is-text>
                                <feather-icon icon="KeyIcon" />
                            </b-input-group-prepend>
                            <b-form-input v-model="captcha" type="text" placeholder="input your captcha" />
                            <b-input-group-append>
                                <div class="btn btn-secondary p-0 pt-1"  @click="remoteCaptcha">
                                    <b-img class="ml-3" v-bind:src="captchaBase64" width="125" height="25" />
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <!-- submit and reset -->
                    <b-button
                        type="submit"
                        variant="primary"
                        class="btn-block mt-4"
                        :disabled="!(passWordValidation && emailValidation && captcha !== '')"
                        @click="login"
                    >
                        Login
                    </b-button>
                    <b-button type="submit" variant="secondary" class="btn-block" @click="goto_forgetpass"> Forget my password </b-button>

                    <b-card-text class="mt-4 text-center">
                        <img src="/favicon.ico" width="15px" height="15px" style="border-radius:5px;margin-bottom:2px" class="mr-1"/> New on our platform?
                        <a class="goto_register" @click="goto_register">Create an account</a>
                    </b-card-text>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardText,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInvalidFeedback,
    BAlert,
    BFormCheckbox,
    BButton,
    BImg,
    BOverlay,
} from "bootstrap-vue";


export default {
    components: {
        BCard,
        BCardTitle,
        BCardText,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormInvalidFeedback,
        BAlert,
        BFormCheckbox,
        BButton,
        BImg,
        BOverlay,
    },

    data() {
        return {
            password: "",
            email: "",
            show_overlay: false,
            vsecs: 0,
            captcha: "",
            captchaId: "",
            captchaBase64: "/cap_loading.png",
        };
    },
    computed: {
        passWordState() {
            return this.password.length != 0 ? "state" : null;
        },
        passWordValidation() {
            return this.$tools.validator.validatePassword(this.password)
        },

        emailState() {
            return this.email.length != 0 ? "state" : null;
        },
        emailValidation() {
            return this.$tools.validator.validateEmail(this.email)
        },
    },
    methods: {
        goto_register() {
            this.$router.push({
                path: "/register",
            });
        },
        goto_forgetpass() {
            this.$router.push({
                path: "/forgetpass",
            });
        },
        async remoteCaptcha() {
            let resp = await this.$api.captcha.getCaptcha();
            // console.log(resp);
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.captchaId = resp.result.id;
            this.captchaBase64 = resp.result.content;
        },
        async login() {
            this.show_overlay = true;
            let resp = await this.$api.user.login(this.email, this.password, this.captchaId, this.captcha);
            this.show_overlay = false;
            if (resp.err != null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                // await this.remoteCaptcha();
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                await this.remoteCaptcha();
                return;
            }

            this.$store.commit("auth/UPDATE_MY_LOGIN_ROLES_PERMISSIONS", {
                web_token: resp.result.user.access_token,
                roles: resp.result.user.roles,
                permissions: resp.result.user.permissions,
            });
            if (resp.result.user.roles.includes("admin")||resp.result.user.roles.includes("maintainer")){
                this.$router.push({
                path: "/",
            });
            }else{
this.$router.push({
                path: "/user_node",
            });
            }
            
        },
    },
    mounted() {
        this.remoteCaptcha();
    },
};
</script>
